<template>
  <div>
    <section
      v-if="layoutInternalCreated"
      id="about"
      class="section-content content-text"
    >
      <div class="container">
        <div class="row">
          <div class="col-lg-7">
            <h2>Aplicação de Insulfilm Automotivo</h2>

            <p>
              Originalmente criado como um adereço, a película de controle solar
              automotivo tornou-se um dos acessórios mais procurados pelos
              motoristas. Hoje, é indispensável proteger o interior do seu
              carro, além de proporcionar conforto térmico ao motorista, o
              insulfilme automotivo se tornou um item de segurança dando maior
              privacidade ao condutor e passageiros.
            </p>

            <h3>Conheça 4 tipos de insulfilme automotivo</h3>

            <h4>1- Insulfilme Tintado</h4>

            <p>
              Os insulfilmes automotivos Tintados ou encapsulados fazem parte do
              filme escuro não reflexivo. Ou seja, são projetados
              especificamente para escurecer os vidros,
              <strong>não tendo nenhum tipo de proteção solar</strong>, servindo
              somente para escurecimento dos vidros.
            </p>

            <p>
              Este tipo de insulfilme automotivo tem em média de 1 a 3 anos de
              garantia, é revestido com corantes ou pigmentos em cola ou
              adesivos de laminação. Por conta disso, apresenta algumas
              desvantagens: baixa durabilidade, mudança de cor e clareamento
              rápido.
            </p>

            <h4>2 - Película automotiva Poliéster</h4>

            <p>
              O insulfilme automotivo de poliéster é fabricado com uma resina
              polimérica da família dos polialiléster. Trata-se de uma película
              de plástico com ótima resistência química e mecânica, alta
              durabilidade e estabilidade dimensional.
            </p>
            
            <p>
              A película profissional, além das características de privacidade,
              ela tem como principal função a redução de radiação ultravioleta e
              de iluminação, sua garantia costuma ser de 5 anos, é uma película
              superior em qualidade e valor, tendo como referência a película
              tintada.
            </p>
            
            <div @click="index = 0" class="page-photo-container">
              <Photo
                :src="photos[0].thumb"
                :title="photos[0].title"
                :description="photos[0].description"
                :idLocation="photos[0].idLocation"
                :openLightBox="false"
              />
            </div>
            <h4>3 - Película automotiva de Segurança/Antivandalismo</h4>

            <p>
              O insulfilm automotivo de segurança tem como principal função, o
              aumento da resistência dos vidros, impedindo que venha a se
              quebrar pela ação de vândalos na intenção de furtos e roubos, ou
              até mesmo em um acidente, não deixando que estilhaços de vidros
              ferem os ocupantes do automóvel, a película antivandalismo é mais
              espesso que os demais modelos.
            </p>
            <p>
              Entretanto, apesar dos vidros ficarem muito resistentes, ainda
              pode ocorrer a quebra.
            </p>

            <h4>4 - Película automotiva de Alta performance - Nano cerâmica</h4>

            <p>
              As películas de alta performance nano cerâmica estão entre os
              <strong>modelos mais completos de insulfilm automotivo</strong>.
            </p>            
            <p>
              Películas automotivas desenvolvidas com nanotecnologia híbrida com
              tripla camada. Não possuem metal em sua composição, garantindo
              <strong
                >excelente conforto térmico, durabilidade e visibilidade
                interna</strong
              >.
            </p>
            <p>
              A coloração do poliéster é feito através de corantes inorgânicos
              que garantem uma cor mais estável ao longo de sua garantia, além
              dos escurecimentos dos vidros, essa película
              <strong>retém 99,9% de radiação ultravioleta</strong> e
              <strong>60% de radiação infravermelho</strong> (essa radiação é a
              responsável pela ardência que sentimos na pele) e sua garantia é
              superior a 7 anos.
            </p>

            <p>
              Geralmente, são produzidas com nanocerâmica, um dos materiais que
              possuem maior índice de resistência. Entre suas várias vantagens,
              podemos listar:
              <strong
                >alta eficácia no bloqueio de raios UV, equilíbrio térmico e
                nitidez óptica</strong
              >.
            </p>

            <h3>Resolução do Contran sobre o Insulfilme</h3>

            <p>
              Em 2 de janeiro de 2023, a nova lei de trânsito relacionada ao
              insulfilm automotivo entrou em vigor no Brasil.
            </p>
            <p>
              Estabelecida pelo Conselho Nacional de Trânsito (Contran), por
              meio da <a href="https://www.gov.br/transportes/pt-br/pt-br/assuntos/transito/conteudo-contran/resolucoes/Resolucao9892022.pdf" target="_blank" rel="nofollow">Resolução 989/2022</a>, o preceito diz respeito ao uso do
              insulfilm automotivo de acordo com os padrões estabelecidos pela
              Resolução. Além disso, os condutores que não cumprirem os novos
              requisitos poderão ser penalizados se forem apanhados durante as
              fiscalizações.
            </p>

            <div @click="index = 0" class="page-photo-container">
              <Photo
                :src="photos[1].thumb"
                :title="photos[1].title"
                :description="photos[1].description"
                :idLocation="photos[1].idLocation"
                :openLightBox="false"
              />
            </div>

            <p>
              De acordo com a nova lei, a película aplicada no vidro do carro
              <strong>não pode ser inferior a 70% na transmitância luminosa</strong> - tanto para
              para-brisas e demais áreas indispensáveis à dirigibilidade do
              veículo.
            </p>

            <p>
              Para os <strong>vidros que não interferem nas áreas de dirigibilidade</strong>, a
              película <strong>não poderá ser inferior a 28%</strong>.
            </p>

            <h3>Instalação do insulfilm automotivo</h3>

            <p>
              É possível aplicar insulfilmes em carros com materiais de
              qualidade e com garantia de fábrica para segurança, estética e
              protenção contra o calor e radiação UV.
            </p>

            <p>
              A <router-link :to="{ name: 'about' }">MF Film</router-link> tem
              mais de 18 anos de experiência com profissionais especializados na
              instalação de insulfilms e garante o serviço completo.
            </p>

            <p>
              <router-link :to="{ name: 'quotation' }"
                >Faça uma cotação</router-link
              >, fale com um especialista e veja como as películas podem ajudar
              na privacidade, decoração e segurança do seu automóvel.
            </p>
          </div>
          <!-- col end -->

          <div class="col-lg-5 section-content-illustration">
            <QuotationCall position="float" />
          </div>
          <!-- col end -->
        </div>
        <!-- row end -->
      </div>
    </section>
  </div>
</template>

<script>
import LayoutInternal from "@/layouts/LayoutInternal.vue";
import { photoExtention } from "@/mixin/photo-extention.js";
import { layoutInternalCreated } from "@/mixin/layout-internal-created-mixin.js";

const QuotationCall = () =>
  import(
    /* webpackChunkName: "QuotationCall" */ "@/components/cotacao/quotation-call.vue"
  );
const Photo = () =>
  import(/* webpackChunkName: "Photo" */ "@/components/photo/photo.vue");

export default {
  mixins: [photoExtention, layoutInternalCreated],

  components: {
    QuotationCall,
    Photo,
  },

  data() {
    return {
      layoutLoad: false,
      index: null,
      photos: [        
        {
          description:
            "Película profissional automotiva para privacidade e redução de radiação ultravioleta",
          idLocation: 1,
          src: "/images/fotos/automotivo/pelicula-automotiva-poliester",
          thumb: "/images/fotos/automotivo/small/pelicula-automotiva-poliester",
          title: "Película Automotiva",
        },
        {
          description:
            "Resolução do Contran para aplicação de película automotiva",
          idLocation: 1,
          src: "/images/fotos/automotivo/pelicula-automotiva-resolucao-contran",
          thumb: "/images/fotos/automotivo/small/pelicula-automotiva-resolucao-contran",
          title: "Película Automotiva",
        },
        
      ],
    };
  },

  created() {
    this.$emit(`update:layout`, LayoutInternal);
    this.$emit(`update:title`, "Insulfilm Automotivo");
    this.$emit(
      `update:introduction`,
      "Instalamos insulfilm em carros para segurança/antivandalismo, estética e proteção solar"
    );
    this.$emit(`update:bgHeader`, "black");
  },
};
</script>

<style lang="scss" scoped>
</style>